export const images= [
    {
      id: 1,
      url: "/static/img/gallery1.jpg",
      title: "Title",
      date: "04/03/2017"
    },
    {
      id: 2,
      url: "/static/img/gallery2.jpg",
      title: "Title",
      date: "04/03/2017"
    },
    {
      id: 3,
      url: "/static/img/gallery3.jpg",
      title: "Title",
      date: "04/03/2017"
    },
    {
      id: 4,
      url: "/static/img/gallery4.jpg",
      title: "Title",
      date: "04/03/2017"
    },
    {
      id: 5,
      url: "/static/img/gallery5.jpg",
      title: "Title",
      date: "04/03/2017"
    },
    {
      id: 6,
      url: "/static/img/gallery6.jpg",
      title: "Title",
      date: "04/03/2017"
    }
]