export const blogs= [
    {
      id: 1,
      date: "1 hour ago",
      thumbnail: "/static/img/blog-1.jpg",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius, dolores facilis maiores minus itaque excepturi.",
      comments: 21,
      likes: 16
    },
    {
      id: 2,
      date: "2 hours ago",
      thumbnail: "/static/img/blog-2.jpg",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius, dolores facilis maiores minus itaque excepturi.",
      comments: 21,
      likes: 16
    },
    {
      id: 3,
      date: "3 hours ago",
      thumbnail: "/static/img/blog-3.jpg",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius, dolores facilis maiores minus itaque excepturi.",
      comments: 21,
      likes: 16
    }
]