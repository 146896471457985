export const skills= [
    {
      id: 1,
      name: "Sketch",
      perecentage: 17.98,
      class: "primary"
    },
    {
      id: 2,
      name: "Photoshop",
      perecentage: 52.0,
      class: "warning"
    },
    {
      id: 3,
      name: "Illustrator",
      perecentage: 17.98,
      class: "danger"
    },
    {
      id: 4,
      name: "CoralDraw",
      perecentage: 25.69,
      class: "info"
    },
    {
      id: 5,
      name: "HTML",
      perecentage: 20.25,
      class: "danger"
    }
]