export const connections= [
        {
          id: 1,
          userAvatar: "/static/img/user-1.jpg",
          userName: "Marisol Bailey",
          designation: "UI Developer"
        },
        {
          id: 2,
          userAvatar: "/static/img/user-2.jpg",
          userName: "Karissa Armstrong",
          designation: "Project Architect"
        },
        {
          id: 3,
          userAvatar: "/static/img/user-3.jpg",
          userName: "Kendall Santos",
          designation: "Sr. iOS Developer"
        },
        {
          id: 4,
          userAvatar: "/static/img/user-4.jpg",
          userName: "Josephine Guzman",
          designation: "UX Designer"
        },
        {
          id: 5,
          userAvatar: "/static/img/user-5.jpg",
          userName: "Tracy Freeman",
          designation: "HR Manager"
        }
      ]